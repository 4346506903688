<template lang="pug">
.videoSort-view 
  ChoiceVideo(
    :show_status="show_status" 
    @returnVideos="returnVideos"
  )
  h5.mt-4 第 {{ regionIndex + 1 }} 區塊
  .row
    .col-lg-3
      input.form-control(v-model="list.title", type="text", placeholder="請輸入區塊名稱")
    .col-lg-3
      b-button.text-btn(
        href="javascript:void(0);" 
        size="lg", 
        variant="outline" 
        @click="openChoice"
      ) 
        p(v-if="list.program.id") {{list.program.name}}
        p(v-else) 請選擇節目影片
    .col-2
      .del-btn.del-btn-top.text-left(@click="deleteArea(regionIndex)")
        b-button.btn(href="javascript:void(0);" size="lg", variant="primary")
          i.mdi.mdi-close 
        span.ml-1.mr-3 刪除
  .row.mt-2
    draggable.col-6(v-model="list.videos", group="people", @start="drag=true", @end="drag=false")
      .group-card.mb-2(v-for="(video, videoIndex) in list.videos", :key="'video' + videoIndex")
        .icon
          i.fas.fa-grip-lines
        .serial_number.ml-2 {{videoIndex+1}}
        .img-box.ml-2
          img(:src="video.image", alt="alt")
          p.text.mb-0.ml-2 {{video.title}}
          .del-btn(@click="deleteItem(regionIndex,videoIndex)")
            b-button.btn(href="javascript:void(0);" size="lg", variant="primary")
              i.mdi.mdi-close  

</template>
<script>
import draggable from 'vuedraggable'
import ChoiceVideo from "@/components/ChoiceVideo"
export default {
  props: ['list', 'regionIndex'],
  name: "videoSort",
  data () {
    return {

      show_status: false,

    }
  },
  watch: {

  },
  created () {

  },
  mounted () {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.show_status = false
    })
  },
  methods: {
    deleteArea (index) {
      this.$emit('deleteArea', index)
    },
    deleteItem (_regionIndex, _videoIndex) {
      this.$emit('deleteItem', _regionIndex, _videoIndex)
    },
    returnVideos (_videos, _program) {
      this.list.videos = _videos
      this.list.program = _program
    },
    openChoice () {
      this.show_status = true
    },
  },
  components: {
    draggable,
    ChoiceVideo
  },
}
</script>
<style lang="sass" scoped>
  .group-card
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start
    .img-box
      display: flex
      align-items: center
      background: #fff
      padding: 10px
      .text
        width: 60%
        max-width: 60%
      img
        width: 66px
  .del-btn-top
    margin-top: 4px
  .del-btn
    text-align: right
    cursor: pointer
    flex: 1
    .btn
      border-radius: 50%
      padding: 0rem .3rem 
  .text-btn
    background-color: #fff
    padding: .4rem 1.2rem
    width: 100%
    border: 1px solid #ddd
    font-size: 16px
    p
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 1

      height: 24px
      margin-bottom: 0
      
      // border-radius: none

</style>

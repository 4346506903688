<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/programsetting") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-tabs.tab-color(content-class v-model="tabIndex")
          //- 編輯節目資訊
          b-tab(title="編輯節目資訊", active)
            .card-body
              form.form-horizontal(role="form")
                .row.mb-2.align-items-end
                  .form-group.col-5.mb-0
                    input.form-control(
                      v-model="program_detail.title"
                      type="text",
                      placeholder="節目名稱"
                    )
                  .col-2.pb-1
                    b-form-checkbox(
                      v-model="show_on_tag",
                      name="checkbox",
                      value="accepted",
                      format="yyyy-MM-dd HH:mm:ss"
                      unchecked-value="not_accepted"
                      @change="changeShow"
                    ) 在分類上顯示
                .row.mb-2
                  .form-group.col-7
                    input.form-control(
                      type="text",
                      v-model="program_detail.presenter"
                      placeholder="主持人"
                    )
                .row.mb-0
                  .form-group.col-7.mb-0
                    label 請選擇播出時間
                    //- br
                    //- b-form-checkbox-group#checkbox-group-2(v-model='selected' name='flavour-2')
                      b-form-checkbox(value='1') 週一
                      b-form-checkbox(value='2') 週二
                      b-form-checkbox(value='3') 週三
                      b-form-checkbox(value='4') 週四
                      b-form-checkbox(value='5') 週五
                      b-form-checkbox(value='6') 週六
                      b-form-checkbox(value='7') 週日

                .row.mb-5.align-items-center
                  .col-lg-2
                    .form-group.mb-0
                      date-picker(
                        v-model="program_detail.start_at"
                        format="HH:mm",
                        :is-24="true"
                        value-type="format",
                        type="time",
                        placeholder="時 : 分"
                      )
                  .col-auto 至
                  .col-lg-2
                    .form-group.mb-0
                      date-picker(
                        v-model="program_detail.end_at"
                        format="HH:mm",
                        :is-24="true"
                        value-type="format",
                        type="time",
                        placeholder="時 : 分"
                      )
                .row.mb-4
                  .col-lg-7.row
                    .col(v-if="!program_detail.image")
                      vue-dropzone(
                        id="dropzone-program", 
                        key="dropzone-program", 
                        ref="myVueDropzone", 
                        :use-custom-slot="true", 
                        :options="dropzoneOptions",
                        @vdropzone-success-multiple="uploadImageSuccess"
                      )
                        .dz-message.needsclick
                          i.h1.text-muted.ri-upload-cloud-2-line
                          h4 上傳節目封面圖片

                    .col(v-if="program_detail.image")
                      .d-flex.align-items-end
                        .dropzone-previews
                          .card.mb-0.shadow-none
                            img.preview-img.rounded.bg-light(:src='program_detail.image' alt='')
                            a.img-del-btn.btn.btn-link.btn-lg.text-muted(@click="deleteImg()")
                              i.fe-x 
                .add-new.mb-3
                  b-button.btn.ml-0.width-md(size="md", variant="outline-dark" @click="show_status = true") 加入Tag

                .row.mb-4.align-items-end
                  .form-group.col-7.mb-0
                    input.form-control(
                      style=" background: rgba(255, 132, 110, 0.29);"
                      type="text",
                      v-model="program_detail.ytlist_main_uri"
                      placeholder="此欄位為所有影片對應清單，請輸入YT對應播放清單網址，請勿輸入短網址(完整版)"
                    )

                .program-text.mb-2 請於下方新增其他節目播放清單
                .row.mb-2(v-if="program_detail.ytlist_sub_uris && program_detail.ytlist_sub_uris.length > 0")
                  .form-group.col-7(v-for="(uri, index) in program_detail.ytlist_sub_uris")
                    input.form-control(
                      v-model="program_detail.ytlist_sub_uris[index]"
                      type="text",
                      placeholder="請輸入YT對應播放清單網址(請勿輸入短網址)"
                    )
                .add-new
                  b-button.btn.ml-0.width-md(size="md", variant="outline-dark" @click="addNewPlayList") ＋新增播放清單



                .mt-5
                  b-button.width-lg.mr-2(v-if="program_id === 'create'" size="md" variant="primary" @click="createProgramDetail" :disabled="saving")  {{ saving ? '執行中...' : '準備完成，下一步' }}
                  b-button.width-lg.mr-2(v-else size="md" variant="primary" @click="updateProgramDetail" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}


          //- 編輯節目頁面
          b-tab(title="編輯節目頁面" :disabled="program_id === 'create'")
            .card-body
              .row.ml-2.mt-2
              .col-12
                h5.mt-3.mb-1 第一區塊 － 熱門影片：按照全站影片的熱門程度進行排序
              .col-10
                videoSort(
                  v-for="(region,index) in setting" 
                  :key="'area' + index" 
                  :list="region" 
                  :regionIndex="index" 
                  @deleteArea="deleteArea" 
                  @deleteItem="deleteItem"
                )

              .row.ml-2.mt-3
                .col-12              
                  b-button.col-lg-5.add-btn.width-lg.mb-2( variant="outline-primary" @click="addArea()") ＋新增區塊

              .row.text-center.mt-3
                .col
                  b-button.width-lg.mr-1(variant="primary" @click="updateOneProgramPageSetting()" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}
                  b-button.width-lg(variant="primary" @click="postOneProgramPageSetting()" :disabled="releasing") {{ releasing ? '發佈中...' : '發佈' }}



  SelectTag(
    :show_status="show_status"
    :video_tag_list="video_tag_list"
    :tag_list="program_detail.tags"
    @updateTags="updateTags"
  )
    
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import vue2Dropzone from "vue2-dropzone";
import videoSort from "@/components/videoSort";
import SelectTag from "@/components/SelectTag";
/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "節目設定",
      items: [
        {
          text: "影音節目管理",
          href: "/programsetting",
        },
        {
          text: "節目設定",
          active: true,
        }
      ],
      dropzoneOptions: {
        url: process.env.VUE_APP_UPLOAD_SERVER_URI + "/media/create/news/default",
        method: "post",
        uploadMultiple: true,
        paramName: "files",
        headers: {
          "Authorization": 'Bearer ' + this.$root.common.getCookie('api-token'),
        },
        previewTemplate: this.template(),
      },

      program_id: '',

      program_detail: {
        title: '',
        presenter: '',
        presenter_at: false,
        on_tags: false,
        start_at: '',
        end_at: '',
        image: '',
        tags: [],
        channel_uri: '',
        ytlist_main_uri: '',
        ytlist_sub_uris: [],
      },



      tabIndex: 0,

      show_on_tag: false,
      selected: '1',

      video_tag_list: [],

      show_status: false,

      setting: {},

      saving: false,
      releasing: false,

    }
  },
  computed: {

  },
  created () {
    // 獲得操作公告ID
    this.program_id = this.$route.params.programId
  },
  mounted () {
    if (this.program_id !== 'create') {
      this.getProgramDetail()
      this.getOneProgramPageSetting()
    }
    this.getSiteVideoTagList()
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.show_status = false
    })
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    vueDropzone: vue2Dropzone,
    videoSort,
    SelectTag
  },
  watch: {
    list_sortType (_val) {
      this.getPlayListVideo()
    },
    'program_detail.on_tags' (_val) {
      if (_val) {
        this.show_on_tag = 'accepted'
      } else {
        this.show_on_tag = 'not_accepted'
      }
    }
  },
  methods: {
    // 處理圖片下載模組
    template () {
      return ` <div class="dropzone-previews">
            <div class="card mb-0 shadow-none">
              <img data-dz-thumbnail src="#" class="preview-img avatar-sm rounded bg-light" alt="">
              <!-- Button -->
              <a href="" class="img-del-btn btn btn-link btn-lg text-muted" data-dz-remove>
                  <i class="fe-x"></i>
              </a>
            </div>
        </div>`;
    },
    // 圖片上傳成功時
    uploadImageSuccess (file, response) {
      console.log("Upload Response:", response)
      this.program_detail.image = response.data[0].url
    },
    // 移除圖片項目
    deleteImg () {
      this.program_detail.image = ''
    },

    changeShow () {
      if (this.show === 'accepted') {
        this.program_detail.on_tags = true
      } else {
        this.program_detail.on_tags = false
      }
    },
    addNewPlayList () {
      const new_one = ''
      this.program_detail.ytlist_sub_uris.push(new_one)
      this.$forceUpdate()
    },

    updateTags (_tags) {
      this.program_detail.tags = _tags
    },


    // 獲得節目詳情
    getProgramDetail () {
      let vm = this
      this.$root.apis.getProgramDetail(this.program_id, function (_response) {
        vm.program_detail = _response.body.data

        if (vm.program_detail.playlists.length > 0) {
          vm.program_detail.ytlist_main_uri = vm.program_detail.playlists.find(el => el.type === 1)?.ytlist_uri || ''
          vm.program_detail.ytlist_sub_uris = vm.program_detail.playlists.filter(el => el.type === 0).map(el => el.ytlist_uri) || []
        }

        const tags = []
        vm.program_detail.tags.forEach(function (_tag_1) {
          tags.push(_tag_1.id)
        })

        vm.program_detail.tags = tags
      })
    },
    // 請求獲取單一節目設定
    getOneProgramPageSetting () {
      let vm = this
      this.$root.apis.getOneProgramPageSetting(this.program_id, 
        function (_response) {
          vm.setting = _response.body.data
        },
        function (_error) {
          vm.$root.common.showErrorNotify(_error.body.data)
        }
      )
    },

    // 獲得影音標籤清單
    getSiteVideoTagList () {
      let vm = this
      this.$root.apis.getSiteVideoTagList({
        page: 1,
      }, function (_response) {
        let response = _response.body.data
        vm.video_tag_list = response.tags
      })
    },

    // 儲存節目詳情
    updateProgramDetail () {
      this.saving = true
      let vm = this
      const item = this.program_detail

      this.$root.apis.updateProgramDetail(this.program_id, item, function () {
        vm.$root.common.showSingleNotify("儲存成功")
        vm.saving = false
      }, function (_response) {
        vm.$root.common.showErrorNotify('欄位填寫不完整')
        vm.saving = false
      })
    },

    // 建立節目詳情
    createProgramDetail () {
      this.saving = true
      let vm = this
      
      const item = this.program_detail

      this.$root.apis.createProgramDetail(item, function (_response) {
        vm.$root.common.showSingleNotify("建立成功")
        vm.saving = false
        vm.program_id = _response.body.data
        vm.$router.push({ path: '/programsetting-edit/' + _response.body.data, params: {} })
        vm.getOneProgramPageSetting()
      }, function (_response) {
        vm.$root.common.showErrorNotify('欄位填寫不完整')
        vm.saving = false
      })
    },
    addArea () {
      const new_item = {
        title: '請選擇節目影片',
        program: { id: "", name: "" },
        videos: []
      }
      this.setting.push(new_item)
    },
    deleteArea (_index) {
      this.setting.splice(_index, 1)
    },
    deleteItem (_regionIndex, _videoIndex) {
      this.setting[_regionIndex].videos.splice(_videoIndex, 1)
    },
    saveSetting () {
      console.log('setting:', this.setting)
    },


    // 更新單一節目設定
    updateOneProgramPageSetting () {
      this.saving = true
      let vm = this
      console.log('setting:', this.setting)

      // 整理成需要的格式
      let data = {
        regions: []
      }

      this.setting.forEach(function (_region, _index) {
        let videos = []
        _region.videos.forEach(function (_video) {
          videos.push(_video.id)
        })

        data.regions[_index] = { id: "", title: "", program_id: "", videos: [] }
        data.regions[_index].videos = videos
        data.regions[_index].program_id = _region.program.id
        data.regions[_index].title = _region.title
      })

      console.log('=> 整理完的資料:', data)

      this.$root.apis.updateOneProgramPageSetting(this.program_id, data, function () {
        vm.$root.common.showSingleNotify("儲存成功")
        vm.saving = false
      }, function (_response) {
        vm.$root.common.showErrorNotify(_response.body.data)
        vm.saving = false
      })
    },

    // 發佈單一節目設定
    postOneProgramPageSetting () {
      this.releasing = true
      let vm = this
      this.$root.apis.postOneProgramPageSetting(this.program_id, function (_response) {
        vm.$root.common.showSingleNotify("節目設置發布成功！")
        vm.releasing = false
      }, function (_response) {
        vm.$root.common.showErrorNotify(_response.body.data)
        vm.releasing = false
      })
    },
  }
};
</script>


<style lang="sass" scoped>
::v-deep .nav-tabs .nav-link
  width: 250px
  text-align: center
  line-height: 30px
::v-deep .vue-dropzone
  padding: 0
  width: 100%
  height: 100%
  max-height: 230px
  max-width: 320px

::v-deep .dropzone-previews
  width: 100%
  height: 100%
  max-height: 230px
  max-width: 320px

  .preview-img
    width: 100%
    height: 100%
    max-height: 230px
    max-width: 320px
    object-fit: cover

    
  .img-del-btn
    position: absolute
    right: -15px
    top: -15px
    background: #1e40b0
    border-radius: 50%
    padding: 0
    height: 30px
    width: 30px
    line-height: 30px
    cursor: pointer
    i
      color: #fff
      cursor: pointer
.program-text
  color: #181818
  font-size: 16px
</style>
<template lang="pug">
b-modal#modal-categorySet(
  v-model="modalShow"
  title="選擇tag",
  title-class="font-18", 
  body-class="p-3" 
  size="md"
)
  label.mb-3 可勾選多個，已勾選
    span.ml-1.mr-1 {{ choice_tag.length }}
    span 個Tag(勾選多個可在多個分類下看到該節目)
  .tag_list
    .tag_item(v-for='(tag, index) in video_tag_list' :key='index' )
      b-button.btn-rounded(:variant="choice_tag.includes(tag.id) ? 'primary' : 'outline-primary'" @click='clickTag(tag.id)') {{ tag.name }}

  
  template(#modal-footer='{ ok, cancel, hide }')
    b-button(size='md' variant='danger' @click='cancel()')
      | 取消操作
    b-button(size='md' variant='success' @click='clickOk()')
      | 確認
</template>
<script>
export default {
  props: ['show_status','video_tag_list','tag_list'],
  data() {
    return {
      modalShow: false,
      choice_tag:[]
    }
  },
  methods:{
    clickTag(_id){
      if (!this.choice_tag.includes(_id)) {
        this.choice_tag.push(_id)
      } else {
        const index = this.choice_tag.indexOf(_id)
        this.choice_tag.splice(index, 1);
      }
    },
    clickOk() {
      this.modalShow = false
      this.$emit('updateTags', this.choice_tag)
    }
  },
  watch: {
    show_status (_status) {
      this.modalShow = _status
    },
    subTypes(_val) {
      this.dataList = JSON.parse(JSON.stringify(_val))
    },
    tag_list(_val){
      this.choice_tag = _val
    }
  },
  components: {
    
  },
}
</script>
<style lang="sass" scoped>
.tag_list
  display: flex
  flex-wrap: wrap
  gap: 5px
</style>

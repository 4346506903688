<template lang="pug">
b-modal#modal-choice-news(
  v-model="modalShow"
  title="選擇節目影片", 
  title-class="font-18", 
  body-class="p-0" 
  size="lg"
)
  .category-list
    .category-item(
      v-for="program in programs" 
      :class="{active:   currentProgramItem.id === program.id }" 
      @click="setCurrentProgramItem(program)"
    ) {{ program.title }}
  .choice-main
    .types-list  最多選擇10則影片
    .news-list 
      .news-item(v-if="!currentProgramVideos.length")
        span 目前沒有影片
      .news-item(
        v-for="video in currentProgramVideos" 
        @click="setVideoItems(video.id)"
      )
        i.fe-check(v-if="currentSelectVideoIds.indexOf(video.id) > -1")
        i.fe-circle(v-else)
        img.news-img(:src="video.image")
        .news-info  
          .news-title {{ video.title  }}
          .news-number 影片發布時間: {{ video.release_at }}
  template(#modal-footer='{ ok, cancel, hide }')
    b-button(size='md' variant='danger' @click='cancel()')
      | 取消操作
    b-button(size='md' variant='success' @click='clickOk()')
      | 選擇

</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: ['show_status'],
  name: "NewsChoice",
  data () {
    return {
      // 所有節目清單
      programs: [],
      // 目前所選節目的項目
      currentProgramItem: { id: "" },
      // 目前所選節目的所有影片
      currentProgramVideos: [],
      // 目前已經選擇的所有影片
      currentSelectVideoIds: [],
      modalShow: false
    }
  },
  watch: {
    show_status (_status) {
      this.modalShow = _status
      this.getProgramList()
    }
  },
  created () {
  },
  methods: {
    // 取得節目清單
    getProgramList () {
      let vm = this
      this.$root.apis.getProgramList({
        per_page: 100,
        page: 1
      }, function (_response) {
        vm.programs = _response.body.items
      })
    },
    // 取得節目下的所有影片
    getProgramVideoList () {
      let vm = this
      this.$root.apis.getProgramVideoList(this.currentProgramItem.id,
        function (_response) {
          vm.currentProgramVideos = _response.body.videos
        })
    },
    // 變更目前的節目主項目
    setCurrentProgramItem (_program) {
      this.currentProgramItem = _program
      this.currentProgramVideos = []
      this.getProgramVideoList()
    },
    // 選擇需要處理的影片項目
    setVideoItems (_videoId) {
      // 尚未選過此影片
      if (this.currentSelectVideoIds.indexOf(_videoId) <= -1) {
        if (this.currentSelectVideoIds.length >= 10) {
          this.$root.common.showErrorNotify("最多只能選擇 10 個影片哦！")
          return
        }
        this.currentSelectVideoIds.push(_videoId)
      }
      // 已經選過此影片
      else {
        this.currentSelectVideoIds.splice(this.currentSelectVideoIds.indexOf(_videoId), 1)
      }
    },
    clickOk () {

      let _videos = []
      let vm = this

      // 處理節目資訊
      let program = {
        id: this.currentProgramItem.id,
        name: this.currentProgramItem.title
      }

      // 處理影片清單
      this.currentSelectVideoIds.forEach(function (_videoId) {
        vm.currentProgramVideos.forEach(function (_video) {
          if (_video.id === _videoId) {
            _videos.push(_video)
          }
        })
      })

      this.$emit('returnVideos', _videos, program)
      this.modalShow = false
    }
  },
  components: {
    draggable
  },
}
</script>
<style lang="sass" scoped>
.category-list
  border-right: 1px solid #e5e8eb
  width: 180px
  max-height: 80vh
  overflow: auto
  
  .category-item
    padding: 0rem 1rem
    margin: 1rem 0
    color: #6b7280e6
    cursor: pointer
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-box-orient: vertical
    -webkit-line-clamp: 2
    

    &.active
      background: #eee
.types-list
  display: flex
  gap: 5px
.choice-main
  flex: 1
  padding: 1rem 0.75rem
  max-height: 80vh
  overflow: auto
.news-list 
  
  .news-item
    padding: 1.5rem 1.25rem  1rem
    display: flex
    align-items: center
    gap: 20px
    cursor: pointer
    i
      font-size: 25px
    img.news-img
      min-width: 174px
      height: 100px
      object-fit: cover
    .news-info
      .news-title
        font-size: 17px
        margin-bottom: 10px

::v-deep .modal-body
  display: flex
</style>
